<template>
  <ItemWrapper>
    <v-row justify="center">
      <v-col cols="12" lg="6">
        <AppCard
          :title="`${$t('Product')} ${product.name || ''}`"
          card-text-classes="text-center"
          :prev-route="prevRoute"
          :loading="pending"
        >
          <div v-if="product && product.id" class="text--disabled">
            <p>
              <b>ArtNr.:</b><br />
              <span>{{ product.number }}</span>
            </p>
            <p>
              <b>Periode:</b><br />
              <span>{{ product.period }}</span>
            </p>
            <p>
              <b>Preis:</b><br />
              <span>{{ product.baseprice }}</span>
            </p>
            <p>
              <b>Beschreibung:</b><br />
              <span>{{ product.description }}</span>
            </p>
            <p>
              <b>Kategorie:</b><br />
              <span>{{ product.category.name }}</span>
            </p>
            <p>
              <b>Subscription Text:</b><br />
              <span>{{ product.subscriptionstext }}</span>
            </p>
            <p v-if="product.tax">
              <b>MwSt.:</b><br />
              <span>{{ product.tax }}</span>
            </p>
          </div>
        </AppCard>
      </v-col>
    </v-row>
  </ItemWrapper>
</template>

<script>
import { defineComponent, onBeforeUnmount, computed, ref } from '@vue/composition-api'

import AppCard from '@/components/UI/AppCard'
import ItemWrapper from '@/layouts/ItemWrapper'

export default defineComponent({
  name: 'ViewProduct',
  components: {
    AppCard,
    ItemWrapper,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // vm.prevRoute = from.fullPath === '/' ? vm.prevRoute : from.fullPath
    })
  },
  setup(props, { root: { $store, $router } }) {
    const prevRoute = ref('/Products')

    // store
    const pending = computed(_ => $store.state.product.pending)
    const product = computed(_ => $store.state.product.product)
    const getProduct = _ => $store.dispatch('product/getProduct')
    const clearProduct = _ => $store.dispatch('product/clearProduct')

    getProduct()
      .then(r => r)
      .catch(error => {
        if (error.response.status === 404) {
          $router.push({ name: 'notFound' })
        }
      })

    onBeforeUnmount(_ => {
      clearProduct()
    })

    return {
      prevRoute,
      pending,
      product,
    }
  },
})
</script>
